'use strict';


$(document).ready(function () {
    var $dropdown = $('.world-of-ao-content').find('.accordion-dropdown');
    $dropdown.on('dropdownValueSelected', function (_event, data) {
        window.location.href = data.value;
    });

});

'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, button) {
    /* OTB Code modification start */
    $.spinner().stop();
    var status;
    if (data.success) {
        button.addClass('active');
        status = 'alert-success';
        button.attr('aria-pressed', 'true');
    } else {
        // button.removeClass('active');
        status = 'error-message';
    }
    /* OTB Code modification ends */
    if ($('.add-to-wishlist-messages').length === 0) {
        $('header').append('<div class="add-to-wishlist-messages add-to-cart-messages" role="alert" aria-live="assertive" id="add-to-wish-list"></div>');
    }
    $('.add-to-wishlist-messages').append('<div class="col-12 add-to-basket-alert add-to-wishlist-alert  ' + status + '" >' + data.msg + '</div>');

    setTimeout(function() {
        $('.add-to-wishlist-messages').empty();
        button.removeAttr('disabled');
    }, 5000);
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('header').append('<div class="add-to-wishlist-messages role="alert" aria-live="assertive"></div>');
    }
    $('.add-to-wishlist-messages').append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function() {
        $('.add-to-wishlist-messages').empty();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]')
        .closest('.product-info')
        .find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterMovedtoWishlist', itemToMove);
}

/**
 * Toggle heart icons
 * @param {Object} wishlistItems - wish list items
 */
function toggleHeartIcon(wishlistItems) {
    $('.add-to-wish-list:not(.active)').each(function() {
        var pid = $(this).attr('class').match(/\bpid-\S*\b/);
        if (pid && pid.length && wishlistItems.indexOf(pid[0].replace('pid-', '')) >= 0) {
            $(this).addClass('active');
        }
    });
}

/**
 * Toggle heart icons for logged-in user
 * @param {Object} wishlistItems - wish list items
 */
function toggleHeartIconForLoggedIn(wishlistItems) {
    toggleHeartIcon(wishlistItems);
}

/**
 * Toggle heart icons for guest user
 */
function toggleHeartIconForGuest () {
    if ($('.header-container').find('.heart-active-icon').hasClass('d-none')) {
        localStorage.removeItem('wishlistItems');
    } else {
        var wishlistItems = localStorage.getItem('wishlistItems');
        if (wishlistItems) {
            wishlistItems = JSON.parse(wishlistItems);
            if (wishlistItems.length) {
                toggleHeartIcon(wishlistItems);
            }
        }
    }
}

module.exports = {
    addToWishlist: function() {
        $(document).on('click', '.add-to-wish-list', function(e) {
            e.preventDefault();
            var $addToWishlistBtn = $(this);
            var url = $(this).data('href');
            var button = $(this);
            var pid = $(this)
                .closest('.product-detail')
                .find('.product-id')
                .html();
            var optionId = $(this)
                .closest('.product-detail')
                .find('.product-option')
                .attr('data-option-id');
            var optionVal = $(this)
                .closest('.product-detail')
                .find('.options-select option:selected')
                .attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            var $sizeSelectionsEl = $('.size-selections'),
                isPDP = $('.product-detail.product-pdp').length > 0;

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $(this).attr('disabled', true);

            //stop the script execution and display an error message if there's no size selected
            //only trigger the size validation on PDP
            if (isPDP && $sizeSelectionsEl.length && $sizeSelectionsEl.find('.size-attribute[aria-checked="true"]').length === 0) {
                const errMsg = {
                    success: false,
                    msg: window.Resources.PLEASE_SELECT_SIZE
                }

                displayMessage(errMsg, button);
                return;
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function(data) {
                    if (data.success) {
                        $.spinner().stop();
                        $('body').trigger('gtm:product:afterAddToWishlist', data);
                        this.updateHeartIcon();
                        if (!data.isLoggedIn && data.redirectUrl) {
                            window.location = data.redirectUrl;
                        } else {
                            displayMessage(data, button);
                        }
                    } else {
                        this.updateHeartIcon();
                        displayMessage(data, button);
                    }
                },
                error: function(err) {
                    displayMessage(err, button);
                },
                updateHeartIcon: function () {
                    $addToWishlistBtn.closest('.product-detail').find('.add-to-wish-list').addClass('active');
                    $('.header-container').find('.heart-active-icon').removeClass('d-none');
                    $('.header-container').find('.heart-inactive-icon').addClass('d-none');
                    this.saveToBrowserStorage();
                },
                saveToBrowserStorage: function () {
                    var wishlistItems = localStorage.getItem('wishlistItems');
                    if (wishlistItems && wishlistItems.length) {
                        wishlistItems = JSON.parse(wishlistItems);
                    } else {
                        wishlistItems = [];
                    }
                    if (wishlistItems.indexOf(pid) === -1) {
                        wishlistItems.push(pid);
                        localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems));
                    }
                }
            });
        });
    },
    moveToWishlist: function() {
        $('body').on('click', '.product-move', function(e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var pid = $(this).data('pid');
            var optionId = $(this)
                .closest('.product-info')
                .find('.lineItem-options-values')
                .data('option-id');
            var optionVal = $(this)
                .closest('.product-info')
                .find('.lineItem-options-values')
                .data('value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function(data) {
                    $('body').trigger('gtm:product:afterAddToWishlist', data);
                    displayMessageAndRemoveFromCart(data);
                },
                complete: function() {
                    $.spinner().stop();
                }
            });
        });
    },
    initWishlistItems: function() {
        toggleHeartIconForGuest();

        $('body').on('search:updateProducts', function () {
            toggleHeartIconForGuest();
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
            try {
                var $addToWishlistBtn = response.container.find('.add-to-wish-list');
                var oldPid = $addToWishlistBtn.attr('class').match(/\bpid-\S*\b/);
                if (oldPid && oldPid.length) {
                    $addToWishlistBtn
                        .removeClass('active')
                        .removeClass(oldPid)
                        .addClass('pid-' + response.data.product.id);
                    toggleHeartIconForGuest();
                }
                // eslint-disable-next-line no-shadow
            } catch (e) {
                // Error encountered while updating wishlist heart icon
            }
        });

        // For Logged in user
        if (window.Resources.loggedIn) {
            var wishlistItems = localStorage.getItem('wishlistItems');
            if (wishlistItems) {
                wishlistItems = JSON.parse(wishlistItems);
                if (wishlistItems.length) {
                    if (wishlistItems[0] !== 'loggedIn') {
                        $.ajax({
                            url: window.Urls.getWishlistItems,
                            dataType: 'json',
                            success: function (data) {
                                wishlistItems = data.items;
                                if (data.items && data.items.length) {
                                    toggleHeartIconForLoggedIn(wishlistItems);
                                }
                                wishlistItems.unshift('loggedIn');
                                localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems));
                            }
                        });
                    } else {
                        toggleHeartIconForLoggedIn(wishlistItems);
                    }
                }
            }
        }
    }
};

function initComponent() {
    var slickOptions = {
        mobileFirst: true,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        centerMode: true,
        centerPadding:
            $('.category-carousel')
                .find('.category')
                .outerWidth() / 2,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    centerMode: false,
                    slidesToShow: 4
                }
            }
        ]
    };
    if ($('.category-carousel').length > 0) {
        $('.category-carousel').slick(slickOptions);
    }
}

module.exports = initComponent;

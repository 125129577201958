'use strict';

var processInclude = require('base/util');
var seoCopy = require('./components/seoCopy');

$(document).ready(function() {
    processInclude(require('./components/shopByCategory'));
    processInclude(require('./components/shopByCategorySlot'));
    processInclude(require('./components/plpAddToCart'));
    processInclude(require('./homepage/video'));
    processInclude(require('./product/wishlist'));
    processInclude(require('./worldOfAo'));

    seoCopy.readMoreListener('homepage-copy');
    processInclude(require('./experience/shopTheLook'));

    if ($('.homepage-promo-banner').length) {
        var $target = $('.homepage-promo-banner');
        var $overlay = $target.find('.banner-overlay');
        var $title = $overlay.find('.title');

        if ($overlay.length && $title.length) {
            $title.replaceWith(`<h1 class="title">${$title.text().trim()}</h1>`);
        }
    }
});

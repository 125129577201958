var customUtil = require('../customUtil');

function initComponent() {
    var slickOptions = {
        mobileFirst: true,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        centerMode: true,
        centerPadding:
            $('.home-category-carousel')
                .find('.category')
                .outerWidth() / 2,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: "unslick"
            }
        ]
    };
    if ($('.home-shop-by-category  .home-category-carousel').length > 0 && !customUtil.isMobile()) {
        $('.home-shop-by-category  .home-category-carousel').slick(slickOptions);
    }
}

module.exports = initComponent;

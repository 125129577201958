module.exports = {
    handleShopTheLookMobile: function() {
        var options = {
            threshold: [0.5, 1]
        };
        
        var observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                var intersecting = entry.isIntersecting;
                var targetClassList = document.querySelector('.stl .js-product-tile-pd').classList;

                if (intersecting) {
                    targetClassList.add('shown');
                } else {
                    targetClassList.remove('shown');
                }
            });
        }, options);
        
        var element = document.querySelector('.stl .js-stl-content');
        observer.observe(element);
    }
};
